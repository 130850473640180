import { KeyboardEventHandler, useEffect, useRef, useState } from "react"
import { Option } from "../../../../types/ui"
import style from "./style.module.scss"
import Image from "next/image"

// source : https://codepen.io/tcomdev/pen/WNXeqoG
export default function Select({
  options,
  value,
  onChange = () => {
    return
  },
  placeholder,
  tabOnly,
}: {
  value?: string | number
  options: Option[]
  onChange?: (value: number | string) => void
  placeholder?: string
  tabOnly?: boolean
}) {
  const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false)
  const [selectedValue, setSelectedValue] = useState<string | number>(value || 0)
  const [changed, setChanged] = useState<boolean>(false)

  const buttonRef = useRef<HTMLButtonElement | null>(null)

  useEffect(() => {
    if (value) {
      if (selectedValue !== value) {
        setSelectedValue(value)
      }
    }
  }, [value])

  useEffect(() => {
    if (selectedValue && changed) {
      onChange(selectedValue)
    }
  }, [selectedValue, changed])

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen)
  }

  const setSelectedThenCloseDropdown = (value: string | number) => {
    setSelectedValue(value)
    setChanged(true)
    setIsOptionsOpen(false)
  }

  const handleKeyDown =
    (value: string | number): KeyboardEventHandler<HTMLLIElement> =>
    (e) => {
      switch (e.key) {
        case " ":
        case "SpaceBar":
        case "Enter":
          e.preventDefault()
          setSelectedThenCloseDropdown(value)
          break
        default:
          break
      }
    }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
        setIsOptionsOpen(false)
      }
    }

    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [buttonRef])

  const optionToOptionElementDiv = (option: Option | undefined) => {
    if (option) {
      const { image, value, label } = option
      return (
        <div className={style.option}>
          {image && <img src={image} alt={label} />}
          <span>{label}</span>
        </div>
      )
    } else {
      return undefined
    }
  }

  return (
    <div className={`${style.selector} ${tabOnly ? style.optionSelect : ""}`}>
      <div className={style.container}>
        <button
          type="button"
          aria-haspopup="listbox"
          aria-expanded={isOptionsOpen}
          className={isOptionsOpen ? style.expanded : ""}
          onClick={toggleOptions}
          ref={buttonRef}
        >
          {optionToOptionElementDiv(options.find((o) => o.value === selectedValue)) || placeholder}
        </button>
        <ul className={`${style.options} ${isOptionsOpen ? style.show : ""} `}>
          {options.map((option) => (
            <li
              key={option.value}
              role="option"
              aria-selected={selectedValue == option.value}
              onKeyDown={handleKeyDown(option.value)}
              onClick={() => {
                setSelectedThenCloseDropdown(option.value)
              }}
            >
              {optionToOptionElementDiv(option)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
